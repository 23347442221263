import logo from "./logo.svg";
import "./App.css";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import backsound from "./assets/sound.mp3";

function App() {
  const player = useRef(null);
  const [start, setStart] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [state, setState] = useState("IDLE");
  const [play, { stop }] = useSound(backsound);

  //state =  IDLE
  const onReady = () => {
    handlePlay();
  };

  const onSeek = (e) => {};

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const handlePlay = () => {
    setIsReady(true);
    setIsPlay(true);
  };

  const handleStop = () => {
    setIsPlay(false);
  };

  // useEffect(() => {
  //   if (player) {
  //   }
  // }, [player]);

  const onProgress = (val) => {
    if (state === "IDLE") {
      if (val.playedSeconds > 2) {
        if (isReady) {
          player.current.seekTo(0.5, "seconds");
        }
      }
    }

    // if (state === "CLEAN_1") {
    //   if (val.playedSeconds > 11.5) {
    //     player.current.seekTo(10.95, "seconds");
    //   }
    // }
  };

  const handleClean = () => {
    if (state === "IDLE") {
      handlePlay();
      setState("CLEAN_1");
      player.current.seekTo(2, "seconds");
      play();
    }

    // else if (state === "CLEAN_1" && player?.current?.getCurrentTime() > 11) {
    //   setState("CLEAN_2");
    //   player.current.seekTo(11.5, "seconds");
    // }
  };

  const handleEnd = () => {
    handlePlay();
    setState("IDLE");
    player.current.seekTo(0, "seconds");
    stop();
  };

  return (
    <div className="w-screen h-screen overflow-hidden bg-black flex justify-center items-center">
      <>
        {start ? (
          <>
            {/* {!isReady && (
              <div className="flex font-semibold justify-center items-center  text-white bg-black w-screen h-screen absolute top-0 left-0 z-20 opacity-100">
                <span>Loading...</span>
              </div>
            )} */}

            {/* <div className="absolute left-0 top-0 flex space-x-5 z-20">
              <button
                className=" text-black font-semibold py-2 px-4 bg-white rounded-xl"
                onClick={() => {
                  alert(JSON.stringify(player.current.player.isPlaying));
                }}
              >
                CHECK
              </button>
            </div> */}

            <div
              onClick={() => handleClean()}
              className="bg-black w-screen h-screen absolute top-0 left-0 z-10 opacity-0"
            ></div>

            <ReactPlayer
              playsinline
              ref={player}
              onProgress={onProgress}
              onEnded={() => handleEnd()}
              onSeek={onSeek}
              onReady={() => onReady()}
              playing={isPlay}
              url="/assets/video.mp4"
              width="100%"
              height="100%"
              controls={false}
              config={{
                file: {
                  forceSafariHLS: true,
                  forceVideo: true,
                },
              }}
            />
          </>
        ) : (
          <>
            <button
              className="text-black font-semibold py-2 px-4 bg-white rounded-xl"
              onClick={() => setStart(true)}
            >
              START
            </button>
          </>
        )}
      </>
    </div>
  );
}

export default App;
